/* eslint-disable no-new */
/* eslint-disable no-unused-vars */

// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import $ from 'jquery';
import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/button';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/tab';
import 'bootstrap/js/dist/util';
// import Stickyfill from 'stickyfilljs';
import baguetteBox from 'baguettebox.js';
import { debounce } from 'lodash-es';
import Components from './components';
import Home from './pages/home';
import ProductsDetail from './pages/products_detail';
import ProductsOverview from './pages/products_overview';
import Contact from './pages/contact';
import '../scss/index.scss';

window.$ = $;
window.JQuery = $;

const files = require.context('../../static/img/svg/svg_defs/', false, /.*\.svg$/);
files.keys().forEach(files);

new Vue({
  el: '#page-container',
  delimiters: ['${', '}'],
  methods: {
    // Pre-render pages when the user mouses over a link
    // Usage: <a href="" @mouseover="prerenderLink">
    prerenderLink: function prerenderLink(e) {
      const head = document.getElementsByTagName('head')[0];
      const refs = head.childNodes;
      const ref = refs[refs.length - 1];
      const elements = Array.from(head.getElementsByTagName('link'));
      const prerenderTag = document.createElement('link');

      elements.forEach((el) => {
        if (('rel' in el) && (el.rel === 'prerender')) {
          el.parentNode.removeChild(el);
        }
      });

      prerenderTag.rel = 'prerender';
      prerenderTag.href = e.currentTarget.href;
      ref.parentNode.insertBefore(prerenderTag, ref);
    },
    // Usage: <a href="" @click="goBack">
    goBack: function goBack(e) {
      if (window.history.length > 2) {
        e.preventDefault();
        window.history.back();
      }
    },
    gallery: function gallery(selector) {
      baguetteBox.run(selector);
    },
    fullHeight: function fullHeight(element, excludes = null) {
      const nodes = document.querySelectorAll(element);
      let totalExcludesHeight = 0;

      // Get the total excluding height
      if (excludes) {
        excludes.forEach((exclude) => {
          const el = document.querySelector(exclude);
          if (el) {
            const offset = el.offsetHeight;
            totalExcludesHeight = [totalExcludesHeight, offset].reduce((a, b) => a + b, 0);
          }
        });
      }

      // Set element height
      if (nodes.length > 0) {
        const windowHeight = window.innerHeight;
        const height = windowHeight - totalExcludesHeight;

        Array.from(nodes).forEach((el) => {
          const node = el;

          // Reset node height
          node.style.height = 'auto';

          // Set new height
          if (node.offsetHeight < height) {
            node.style.height = `${height}px`;
          }
        });
      }
    },
    resizeHandler: function resizeHandler() {
      this.fullHeight('[data-full-height]', ['#site-nav']);
    },
    /* stickyPolyfill: function stickyPolyfill(selector) {
      const elements = document.querySelectorAll('selector');
      Stickyfill.add(elements);
    }, */
    windowLoadedHandler: function windowLoadedHandler(e) {
      this.resizeHandler();
    },
    initPage: function initPage() {
      // It would be better if each page had a new vue object
      const main = document.querySelector('main');
      if (main && main.hasAttribute('data-page')) {
        const page = main.getAttribute('data-page');

        switch (page) {
          case 'home': {
            Home.init();
            break;
          }
          case 'products-detail': {
            ProductsDetail.init();
            break;
          }
          case 'products-overview': {
            ProductsOverview.init();
            break;
          }
          case 'contact': {
            Contact.init();
            break;
          }
          default:
            break;
        }
      }
    },
  },
  data() {
    return {
      showOffcanvas: false,
    };
  },
  mounted() {
    this.initPage();
    this.resizeHandler();
    // this.stickyPolyfill('[data-sticky]');
    const cookies = document.getElementById('cookies');
    if (document.querySelectorAll('[data-gallery]').length > 0) {
      this.gallery('[data-gallery]');
    }

    if (cookies) {
      if (!localStorage.getItem('acceptCookies')) {
        cookies.classList.remove('d-none');
        const btnCookies = document.getElementById('btn-cookies');

        btnCookies.addEventListener('click', () => {
          localStorage.setItem('acceptCookies', 'true');
        });
      } else {
        cookies.remove();
      }
    }

    window.addEventListener('resize', debounce(this.resizeHandler, 100));
    window.addEventListener('load', this.windowLoadedHandler);
  },
});
