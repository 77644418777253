import axios from 'axios';
import { TweenLite, Power2, ScrollToPlugin } from 'gsap/all';
import Map from '../map/map';

const Contact = {
  map: null,
  mapOptions: null,

  init: () => {
    Contact.initEvents();
    Contact.initMap();
  },

  initEvents: () => {
    const formControl = document.querySelectorAll('.form-control');
    Array.from(formControl).forEach((el) => {
      el.addEventListener('input', Contact.inputChange);
    });
    document.getElementById('contact-form').addEventListener('submit', Contact.contactFormSubmitHandler);
  },

  initMap: () => {
    const map = document.getElementById('map');

    if (map) {
      Contact.mapOptions = JSON.parse(map.dataset.options);

      Contact.map = new Map({
        options: {
          center: { lat: parseFloat(Contact.mapOptions.lat), lng: parseFloat(Contact.mapOptions.lng) },
          zoom: Contact.mapOptions.zoom,
        },
      });

      map.addEventListener('mapLoaded', Contact.mapLoaded);
    }
  },

  mapLoaded: () => {
    Contact.map.addMarker([{
      position: { lat: parseFloat(Contact.mapOptions.lat), lng: parseFloat(Contact.mapOptions.lng) },
      title: Contact.mapOptions.title,
    }]);
  },

  inputChange: (e) => {
    e.target.classList.remove('is-invalid', 'is-valid');
  },

  contactFormSubmitHandler: (e) => {
    // Prevent the form from actually submitting
    e.preventDefault();
    const contact = document.getElementById('contact');
    const contactForm = document.getElementById('contact-form');
    const successMessage = document.getElementById('contact-success');
    const errorMessage = document.getElementById('contact-error');
    const data = new FormData(contactForm);

    successMessage.classList.add('d-none');
    errorMessage.classList.add('d-none');

    // Send it to the server
    axios({
      method: 'post',
      url: '/',
      data,
    })
      .then((response) => {
        let listErrors = contact.querySelector('.list-errors');

        // Remove existing list errors
        if (listErrors) {
          listErrors.remove();
        }

        if (response.data.errors) {
          // Create errors list and apply classes
          listErrors = document.createElement('ul');
          listErrors.classList.add('list-unstyled', 'list-errors', 'text-danger', 'text-center', 'mb-3');

          // Create errors list
          Object.keys(response.data.errors).forEach((key) => {
            const errorId = key;
            const error = response.data.errors[key];
            const errorItem = document.createElement('li');
            const errorField = document.getElementById(errorId);
            errorItem.appendChild(document.createTextNode(error));
            listErrors.appendChild(errorItem);

            if (errorField) {
              errorField.classList.add('is-invalid');
            }
          });

          // Add to DOM
          contactForm.parentNode.insertBefore(listErrors, contactForm);
        } else {
          contactForm.reset();
          successMessage.classList.remove('d-none');
        }
      })
      .catch(() => {
        errorMessage.classList.remove('d-none');
      })
      .finally(() => {
        const plugins = [ScrollToPlugin]; // eslint-disable-line no-unused-vars
        TweenLite.to(window, 0.5, { scrollTo: contact, ease: Power2.easeInOut });
      });
  },
};

export default Contact;
