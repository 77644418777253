import $ from 'jquery';
import 'slick-carousel';

window.$ = $;
window.JQuery = $;

const ProductsDetail = {
  init: () => {
    ProductsDetail.carousel();
  },

  carousel: () => {
    $('.slick-carousel-main').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      dots: false,
      fade: true,
      asNavFor: '.slick-carousel-thumbs',
      responsive: [
        {
          breakpoint: 992,
          settings: {
            dots: true,
            fade: false,
            speed: 500,
            cssEase: 'cubic-bezier(0.77, 0, 0.175, 1)',
          },
        },
      ],
    });

    $('.slick-carousel-thumbs').slick({
      slidesToShow: 4,
      asNavFor: '.slick-carousel-main',
      focusOnSelect: true,
      dots: false,
      vertical: true,
      centerMode: true,
      infinite: false,
    });
  },
};

export default ProductsDetail;
