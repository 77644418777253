import $ from 'jquery';
import 'slick-carousel';

window.$ = $;
window.JQuery = $;

const Home = {
  init: () => {
    Home.carousel();
  },

  carousel: () => {
    $('.slick-carousel').slick({
      arrows: false,
      dots: true,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 2500,
      speed: 750,
      cssEase: 'cubic-bezier(0.77, 0, 0.175, 1)',
    });
  },
};

export default Home;
