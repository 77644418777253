export default function loadScript(src) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    const tag = document.getElementsByTagName('script')[0];
    script.src = src;
    script.async = true;
    script.onerror = (err) => {
      reject(err, script);
    };

    script.onload = () => {
      resolve();
    };

    tag.parentElement.insertBefore(script, tag);
  });
}
