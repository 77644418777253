<!-- <scroll-down url="#intro" class="test"/> -->
<template>
  <a v-bind:href="url" v-bind:class="['c-scroll-down']" @click="smoothScroll">
    <div class="icon-container">
      <icon type="arrow" class="arrow-bottom fill-white"></icon>
    </div>
  </a>
</template>

<script>
import { TweenLite, Power2, ScrollToPlugin } from 'gsap/all';

export default {
  props: {
    url: String,
  },
  methods: {
    smoothScroll(e) {
      const id = e.currentTarget.hash.substr(1);
      const target = document.getElementById(id);
      const plugins = [ScrollToPlugin]; // eslint-disable-line no-unused-vars

      if (target) {
        e.preventDefault();
        TweenLite.to(window, 0.5, { scrollTo: target, ease: Power2.easeInOut });
      }
    },
  },
};
</script>
