export default class Filter {
  constructor() {
    this.form = null;
    // this.url = new URL(window.location); // Needs IE11 Polyfill - https://github.com/lifaon74/url-polyfill
    this.activeFilterNodes = null;
    // this.activeFilterNodes = new URLSearchParams(this.url.search);
  }

  init(form) {
    if (form && form instanceof Node) {
      this.form = form;
      this.getActiveFilters();
      this.setActiveFilters();
    } else {
      throw new Error(`${form} is not an instance of Node`);
    }
  }

  getActiveFilters() {
    this.activeFilterNodes = this.form.querySelectorAll('input:checked');
  }

  setActiveFilters() {
    const activeFilters = document.getElementById('active-filters');
    const list = activeFilters.querySelector('ul');
    let listItem = activeFilters.querySelector('li');

    if (this.activeFilterNodes.length > 0 && activeFilters) {
      const visibilityClass = activeFilters.getAttribute('data-visible');
      const listItemTemplate = listItem.cloneNode(true);

      listItem.remove();
      activeFilters.classList.add(visibilityClass);

      Array.from(this.activeFilterNodes).forEach((value) => {
        listItem = listItemTemplate.cloneNode(true);
        const button = listItem.querySelector('button');
        const additionalMarkup = button.innerHTML;
        button.innerHTML = value.getAttribute('data-label') + additionalMarkup;
        button.setAttribute('data-id', value.id);
        button.addEventListener('click', this.removeFilter.bind(this));
        list.appendChild(listItem);
      });
    }
  }

  removeFilter(e) {
    const button = e.currentTarget;
    const input = document.getElementById(button.getAttribute('data-id'));
    input.checked = false;
    button.removeEventListener('click', this.removeFilter);
    this.form.submit();
  }
}
