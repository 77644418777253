import Filter from '../filter/filter';

const ProductsOverview = {
  init: () => {
    ProductsOverview.initFilters();
  },

  initFilters: () => {
    const form = document.getElementById('filters-form');

    if (form) {
      const filter = new Filter();
      filter.init(form);
      const menus = form.querySelectorAll('.dropdown-menu');
      if (menus) {
        Array.from(menus).forEach((menu) => {
          menu.addEventListener('click', (e) => {
            e.stopPropagation();
          });
        });
      }
    }
  },
};

export default ProductsOverview;
