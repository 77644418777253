<!-- <icon type="arrow" class="arrow-right fill-white"></icon> -->
<template>
  <svg v-bind:class="['icon shape-' + type]">
    <use v-bind:xlink:href="href"></use>
  </svg>
</template>

<script>
export default {
  props: {
    type: String,
  },
  computed: {
    href() {
      const baseUrl = `${window.location.protocol}//${window.location.host}`;
      return `${baseUrl}/static/img/svg/svg-defs.svg#shape-${this.type}`;
    },
  },
};
</script>
