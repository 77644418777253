<template>
  <div v-bind:class="['c-offcanvas', { open: isOpen }]">
    <slot></slot>
  </div>
</template>

<script>
import { debounce } from 'lodash-es';

export default {
  data() {
    return {
      isOpen: false,
    };
  },

  props: {
    value: {
      type: Boolean,
    },

    closeOnExternalClick: {
      type: Boolean,
      default: true,
    },

    closeOnEsc: {
      type: Boolean,
      default: true,
    },
  },

  watch: {
    value(val) {
      this.toggle(val);
    },
  },

  methods: {
    clickOutside(e) {
      if (!this.$el.contains(e.target) && this.isOpen) {
        // this.toggle(false);
        this.$emit('input', false);
      }
    },

    esc(e) {
      if (e.keyCode === 27 && this.isOpen) {
        // this.toggle(false);
        this.$emit('input', false);
      }
    },

    toggle(show) {
      this.isOpen = show;

      if (show) {
        document.body.classList.add('offcanvas-open');
      } else {
        document.body.classList.remove('offcanvas-open');
      }
    },

    resizeHandler() {
      // @TODO: Probably need a better check here
      if (window.getComputedStyle(this.$el, null).getPropertyValue('transform') === 'none') {
        this.$emit('input', false);
      }
    },
  },

  created() {
    if (this.closeOnExternalClick) {
      document.addEventListener('click', this.clickOutside);
    }

    if (this.closeOnEsc) {
      document.addEventListener('keydown', this.esc);
    }
  },

  mounted() {
    window.addEventListener('resize', debounce(this.resizeHandler, 100));
  },

  beforeDestroy() {
    if (this.closeOnExternalClick) {
      document.removeEventListener('click', this.clickOutside);
    }

    if (this.closeOnEsc) {
      document.removeEventListener('keydown', this.esc);
    }

    window.removeEventListener('resize', this.resize);
  },
};
</script>
