/* global google */

import { merge } from 'lodash-es';
import mapIcon from '../../assets/marker.png';
import loadScript from '../utils/load_script';
import styles from './map_styles';

export default class Map {
  constructor(args = {}) {
    const defaults = {
      id: 'map',
      key: 'AIzaSyCOsdKmGCt1LCuQ9iXkee1AzBdY2cAbMDo',
      options: {
        center: { lat: 50.894945, lng: 4.341546 },
        zoom: 12,
        styles,
      },
    };
    this.map = null;
    // this.mapLoaded = new Event('mapLoaded');
    this.mapLoaded = document.createEvent('Event');
    this.mapLoaded.initEvent('mapLoaded', true, true);
    // Object.assign(defaults, args); doesn't merge nested values but rather replaces them (shallow merge vs deep merge)
    this.args = merge(defaults, args);
    this.load();
  }

  // Asynchronous load Google Maps
  load() {
    loadScript(`https://maps.googleapis.com/maps/api/js?key=${this.args.key}`)
      .then(() => {
        this.initMap();
      })
      .catch((err) => {
        throw err;
      });
  }

  // Initialize map
  initMap() {
    const map = document.getElementById(this.args.id);
    if (map) {
      this.map = new google.maps.Map(document.getElementById(this.args.id), this.args.options);
      google.maps.event.addListenerOnce(this.map, 'idle', () => {
        map.dispatchEvent(this.mapLoaded);
      });
    } else {
      throw new Error(`[initMap] Element with id: ${this.args.id} does not exist`);
    }
  }

  addMarker(markers) {
    const container = [];
    const bounds = new google.maps.LatLngBounds();
    const defaults = {
      map: this.map,
      animation: google.maps.Animation.DROP,
      icon: mapIcon,
    };

    markers.forEach((markerOptions) => {
      const options = Object.assign(defaults, markerOptions);
      const marker = new google.maps.Marker(options);
      container.push(marker);

      // Extend the bounds
      bounds.extend(marker.position);
    });

    /**
     * If we have multiple markers, set the center and zoom level automatically
     * A single marker will utilize the map's center and zoom level instead
     */
    if (container.length > 1) {
      this.map.fitBounds(bounds);
    }
  }
}
