// import Vue from 'vue';
// import Icons from './components/Icons.vue';
// import ScrollDown from './components/ScrollDown.vue';
// import Offcanvas from './components/offcanvas/Offcanvas.vue';
// // Import all components here
//
//
// Vue.component('icon', Icons);
// Vue.component('scroll-down', ScrollDown);
// Vue.component('Offcanvas', Offcanvas);

// Attach all components to Vue here

import Vue from 'vue';

// Automatically register all Vue components located within the /src/components folder.
const requireComponent = require.context(
  // The relative path of the components folder
  './components',
  // Whether or not to look in subfolders
  true,
  // The regular expression used to match base component filenames
  /[A-Z]\w+\.(vue|js)$/,
);

requireComponent.keys().forEach((fileName) => {
  // Get component config
  const componentConfig = requireComponent(fileName);
  let cleanFileName = fileName.replace(/^\.\/(.*)\.\w+$/, '$1');

  // Remove subdirectories
  cleanFileName = cleanFileName.split('/');
  const [componentName] = cleanFileName;

  // Register component globally
  Vue.component(
    componentName,
    // Look for the component options on `.default`, which will
    // exist if the component was exported with `export default`,
    // otherwise fall back to module's root.
    componentConfig.default || componentConfig,
  );
});
